import type { RadioChangeEvent } from 'antd';
import { request } from 'egenie-utils';
import { cloneDeep } from 'lodash';
import { action, observable } from 'mobx';

export class SetCourierStore {
  constructor({ parent }) {
    this.parent = parent;
  }

  @observable public parent;

  @observable public visible = false;

  @observable public loading = false;

  @observable public copyWmsCourier = {};

  @action public onShow = (): void => {
    this.copyWmsCourier = cloneDeep(this.parent.wmsToCourier);
    this.visible = true;
  };

  @action public confirmCourier = async(): Promise<void> => {
    Object.assign(this.parent.wmsToCourier, { ...this.copyWmsCourier });
    let fee = 0;
    const arr = [];
    Object.keys(this.copyWmsCourier).map((key) => {
      const currentCourier = this.copyWmsCourier?.[key]?.postList.find((v) => v.courierId === this.copyWmsCourier?.[key]?.currentCourierId);
      const currentFee = currentCourier?.postFee;
      fee += currentFee;
      arr.push({
        cloudWmsId: key,
        defaultCourierId: this.copyWmsCourier?.[key]?.defaultCourierId,
      });
    });

    await request({
      url: '/api/mall/rest/post/setDefaultCourier',
      method: 'post',
      data: arr,
    });

    this.parent.fright = fee;
    this.closeVisible();
  };

  @action public closeVisible = (): void => {
    this.visible = false;
    this.copyWmsCourier = {};
  };

  @action public onChangeCourier = (e: RadioChangeEvent, wmsId: number): void => {
    console.log(e);
    const { value } = e.target;
    this.copyWmsCourier[wmsId].currentCourierId = value;
  };

  @action public setDefaultCourier = (wmsId: number, courierId: number): void => {
    this.copyWmsCourier[wmsId].defaultCourierId = courierId;
    this.copyWmsCourier[wmsId].currentCourierId = courierId;
  };
}
