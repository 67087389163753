import { observer } from 'mobx-react';
import React from 'react';
import { PUBLIC_IMAGE_URL } from '../../utils/common';
import styles from './index.less';
import type Store from './store';

export const WorryFree: React.FC<{ store: Store; }> = observer((props) => {
  const { openWorryFreeModal, worryFreeAmount } = props.store;
  return (
    <div
      className={styles.worryFree}
      onClick={openWorryFreeModal}
    >
      <header className={styles.worryFreeHeader}>
        无忧退货
      </header>
      <div className={styles.worryFreeTips}>
        本单部分商品支持无忧退货，购买服务15天内可无理由退货
      </div>
      <div className={styles.worryFreeClick}>
        {worryFreeAmount
          ? (
            <span>
              {`¥${worryFreeAmount.toFixed(2)}`}
            </span>
          ) : (
            <span>
              请选择
            </span>
          )}
        <img src={`${PUBLIC_IMAGE_URL}rightArrowRed.png`}/>
      </div>
    </div>
  );
});
