import { Button, Form, Input } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';
import type Store from './store';

const FormItem = Form.Item;

export const BottomOptions: React.FC<{ store: Store; }> = observer((props) => {
  const {
    setCourierStore,
    setBottomRef,
    totalNumber,
    deliveryType,
    fright,
    totalAmount,
    onBottomFormChange,
    remarkLength,
    worryFreeAmount,
    receiverInfo,
    postList,
    validateInfo,
    submitLoading,
    postAmountDiscount,
    perBillingProjectCosts,
    totalPerBillingProjectCosts,
  } = props.store;
  const finalAmount = deliveryType === 0 ? totalAmount + fright + worryFreeAmount + totalPerBillingProjectCosts : totalAmount + totalPerBillingProjectCosts;
  const renderItem = () => {
    return perBillingProjectCosts?.map(({
      productName,
      amount,
    }) => {
      return (
        <div
          className={`${styles.mv16} ${styles.mr40}`}
        >
          {productName}
          :
          {amount}
        </div>
      );
    });
  };
  return (
    <div className={styles.bottomOptions}>
      {deliveryType === 0 && (
        <div
          className={styles.feeStyle}
          onClick={() => {
            setCourierStore.onShow();
          }}
        >
          <div>
            运费：
          </div>
          <div className={styles.postfee}>
            {Number(fright)
              .toFixed(2)}
            元
            <i
              className="icon-sq"
              style={{
                color: styles.colorHead,
                fontSize: 8,
                marginLeft: 4,
              }}
            />
          </div>
          {Boolean(postAmountDiscount?.hasCoupon) && (
            <div className={styles.postAmountDiscount}>
              首单免运费(&yen;
              {postAmountDiscount?.discountAmount}
              )
            </div>
          )}
        </div>
      )}
      {perBillingProjectCosts && perBillingProjectCosts.length > 0 && (
        <div className={styles.billingProjectCosts}>
          {renderItem()}
        </div>
      )}
      <Form
        onValuesChange={onBottomFormChange}
        ref={setBottomRef}
      >
        <FormItem
          label="备注"
          name="remark"
        >
          <Input
            className={styles.input}
            maxLength={50}
            placeholder="请输入备注信息"
            suffix={remarkLength || <span/>}
          />
        </FormItem>
      </Form>
      <div className={styles.result}>
        <div>
          已选商品
          <span className={styles.red}>
            {totalNumber}
          </span>
          件，合计：
          {/* { */}
          {/*   deliveryType === 0 && ( */}
          {/*     <> */}
          {/*       ( */}
          {/*       <span> */}
          {/*         含运费¥ */}
          {/*         {fright.toFixed(2) || '0.00'} */}
          {/*         /!* { ` ，无忧退货服务费¥${worryFreeAmount.toFixed(2)}`} *!/ */}
          {/*       </span> */}
          {/*       )： */}
          {/*     </> */}
          {/*   ) */}
          {/* } */}

          <span className={styles.totalPrice}>
            &yen;
            {finalAmount.toFixed(2)}
          </span>
        </div>
        {deliveryType === 0 && (
          <div>
            {receiverInfo}
          </div>
        )}
      </div>
      <div className={styles.settleBtn}>
        <Button
          loading={submitLoading}
          onClick={validateInfo}
          type="primary"
        >
          {deliveryType === 0 ? '去结算' : '提交订单'}
        </Button>
      </div>
    </div>
  );
});
