import type { FormInstance } from 'antd';
import { message } from 'antd';
import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import { action, observable } from 'mobx';
import { api } from '../../../../utils';
import type { Address } from '../../interface';
import type ParentStore from '../../store';
import type { City, District, Option, Province } from './interface';

export class EditAddressStore {
  constructor({ parent }) {
    this.parent = parent;
  }

  // snippets

  @observable public loading = false;

  @observable public parent: ParentStore = null;

  @observable public visible = false;

  @observable public confirmLoading = false;

  @observable public mallReceiverAddressId: number = null; // 地址id

  @observable public addressOptions: Option[] = [];

  @observable public formRef: FormInstance;

  @observable public receiverAddress: Address = null;

  // 关闭弹窗
  @action public onClose = (): void => {
    this.mallReceiverAddressId = null;
    this.visible = false;
    this.formRef?.resetFields();
  };

  @action public setRef = (ref: FormInstance): void => {
    this.formRef = ref;
  };

  @action public onShow = (mallReceiverAddressId?: number): void => {
    this.mallReceiverAddressId = mallReceiverAddressId;
    this.visible = true;
    this.queryAllAddress();
  };

  @action private queryAllAddress = async(): Promise<void> => {
    this.loading = true;

    // 请求具体地址
    const res: BaseData<Address> = await request({ url: `${api.queryAddressById}?receiverAddressId=${this.mallReceiverAddressId}` });
    const {
      provinceId,
      cityId,
      districtId,
      receiverName,
      receiverMobile,
      receiverAddress,
      defaultAddress,
      province,
      city,
      district,
    } = res?.data;
    const districtList = await this.queryDistrict(cityId);
    const cityList = await this.queryCity(provinceId);
    const targetCity = cityList.find((el) => el.value === `${cityId}`);
    console.log(targetCity);

    targetCity.children = districtList;
    await this.queryProvince();
    const targetProvince = this.addressOptions.find((el) => el.value === `${provinceId}`);
    console.log(targetProvince);
    targetProvince.children = cityList;
    this.formRef?.setFieldsValue({
      receiverName,
      receiverMobile,
      receiverAddress,

      // address: [
      //   `${provinceId}`,
      //   `${cityId}`,
      //   `${districtId}`,
      // ],
      address: `${province}${city}${district}`,
      defaultAddress,
    });
    this.receiverAddress = res.data;

    this.loading = false;
  };

  // 请求省份
  @action public queryProvince = async(): Promise<void> => {
    const res: BaseData<Province[]> = await request({ url: api.queryProvince });
    this.addressOptions = res?.data?.map((el) => {
      return {
        label: el.provinceName,
        value: `${el.id}`,
        isLeaf: false,
      };
    });
  };

  // 请求城市
  @action public queryCity = async(provinceId: string | number): Promise<Option[]> => {
    const res: BaseData<City[]> = await request({ url: `${api.queryCity}?provinceId=${provinceId}` });
    const cityList = res?.data?.map((el) => {
      return {
        label: el.cityName,
        value: `${el.id}`,
        isLeaf: false,
      };
    });
    return Promise.resolve(cityList);
  };

  // 请求地区
  @action public queryDistrict = async(cityId: string | number): Promise<Option[]> => {
    const res: BaseData<District[]> = await request({ url: `${api.queryDistrict}?cityId=${cityId}` });
    const districtList = res?.data?.map((el) => {
      return {
        label: el.districtName,
        value: `${el.id}`,
        isLeaf: true,
      };
    });
    return Promise.resolve(districtList);
  };

  public loadData = async(selectedOptions: Option[]): Promise<void> => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;
    if (selectedOptions.length === 1) {
      targetOption.children = await this.queryCity(targetOption.value);
      targetOption.loading = false;
      this.addressOptions = [...this.addressOptions];
    } else if (selectedOptions.length === 2) {
      targetOption.children = await this.queryDistrict(targetOption.value);
      targetOption.loading = false;
      this.addressOptions = [...this.addressOptions];
    }
  };

  // 保存
  @action public onOk = () => {
    this.confirmLoading = true;
    this.formRef?.validateFields()
      .then(() => {
        const {
          receiverName,
          receiverMobile,
          address,
          receiverAddress,
          defaultAddress,
        } = this.formRef.getFieldsValue();
        console.log(address);

        const [
          provinceId,
          cityId,
          districtId,
        ] = address;
        const params = {
          receiverName,
          receiverMobile,
          receiverAddress,
          defaultAddress: defaultAddress ? 1 : 0,
          mallReceiverAddressId: this.mallReceiverAddressId,
          province: this.receiverAddress?.province,
          city: this.receiverAddress?.city,
          district: this.receiverAddress?.district,
          provinceId: this.receiverAddress?.provinceId,
          cityId: this.receiverAddress?.cityId,
          districtId: this.receiverAddress?.districtId,
          galleryReceiverAddressId: this.mallReceiverAddressId,

        };
        const url = this.mallReceiverAddressId ? api.updateReceiverAddress : api.addReceiverAddress;
        return request({
          url,
          method: 'post',
          data: params,
        })
          .then(() => {
            message.success('修改地址成功');
            if (defaultAddress) {
              this.parent.setAddressAfterDefault(this.mallReceiverAddressId);
            }
            this.onClose();
            this.parent?.editAddressWriteBack(params);
          });
      })
      .finally(() => {
        this.confirmLoading = false;
      });
  };
}
