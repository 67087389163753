import { observer } from 'mobx-react';
import React from 'react';
import { Navigation, WorryFreePurchase } from '../components';
import { AddressManager } from './addressManager';
import { BottomOptions } from './bottomOptions';
import { GoodsList } from './goodsList';
import styles from './index.less';
import { EditAddressModal } from './modal/editAddress/index';
import { SetCourier } from './modal/setCourier';
import { SuccessModal } from './modal/successModal/index';
import Store from './store';
import { WorryFree } from './worryFree';

const store = new Store();

const ConfirmOrder: React.FC<unknown> = observer((props) => {
  const { deliveryType, editAddressStore, worryFreePurchaseStore, showFreeWorryEntrance, onChangeDeliveryType, ownerCustomer, successVisible, setCourierStore } = store;
  return (
    <>
      <Navigation/>
      <div className={styles.pageContainer}>
        <div className={styles.topContainer}>
          {ownerCustomer && (
            <div className={styles.tabWrapper}>
              <div
                className={deliveryType === 0 ? styles.activeTab : styles.unActiveTab}
                onClick={() => onChangeDeliveryType(0)}
              >
                物流配送
              </div>
              <div
                className={deliveryType === 1 ? `${styles.activeTab} ${styles.ml4}` : `${styles.unActiveTab} ${styles.ml4}`}
                onClick={() => onChangeDeliveryType(1)}
              >
                档口自提
              </div>
            </div>
          )}
          {deliveryType === 0 && (<AddressManager orderCheckStore={store}/>)}
        </div>
        <div className={styles.bottomContainer}>
          {deliveryType === 0 && showFreeWorryEntrance && (<WorryFree store={store}/>)}
          <GoodsList store={store}/>
          <BottomOptions store={store}/>
        </div>
      </div>
      <EditAddressModal store={editAddressStore}/>
      <WorryFreePurchase store={worryFreePurchaseStore}/>
      <SuccessModal modalVisible={successVisible}/>
      <SetCourier store={setCourierStore}/>
    </>
  );
});

export default ConfirmOrder;
