import { observer } from 'mobx-react';
import React from 'react';
import { replacePath, splitPrice } from '../../utils';
import { PUBLIC_IMAGE_URL } from '../../utils/common';
import { Price, Tag } from '../components';
import styles from './index.less';
import type Store from './store';

interface Props {
  store?: Store;
}

const titleList: Array<{ title: string; key: string; style?: React.CSSProperties; }> = [
  {
    title: '商品信息',
    key: 'goodsInfo',
    style: {
      width: '274px',
      textAlign: 'center',
    },
  },
  {
    title: '颜色/尺码',
    key: 'spec',
    style: {
      width: '220px',
      marginLeft: '78px',
    },
  },
  {
    title: '单价',
    key: 'price',
    style: { width: '215px' },
  },
  {
    title: '数量',
    key: 'count',
    style: {
      width: '163px',
      paddingLeft: '4px',
    },
  },
  {
    title: '金额',
    key: 'amount',
  },
];
const GoodsList = observer((props: Props) => {
  const {
    deliveryType,
    goodsListByVendor,
    goodsListByWareHouse,
  } = props.store;
  const goodsList = deliveryType === 0 ? goodsListByWareHouse : goodsListByVendor;
  return (
    <div className={styles.goodsList}>
      <div className={styles.title}>
        下单商品
      </div>
      <div className={styles.rowHeader}>
        {titleList.map((el) => {
          return (
            <div
              key={el.key}
              style={{ ...el.style }}
            >
              {el.title}
            </div>
          );
        })}
      </div>
      {goodsList && goodsList.map((vendor) => {
        const totalAmount = vendor.totalAmount;
        return (
          <div
            className={styles.goodsByVendor}
            key={vendor.vendorId}
          >

            <div className={styles.vendorTitle}>
              {
                deliveryType === 0 ? (
                  <span>
                    {vendor.warehouseName === '供应商发货' ? `${vendor.vendorName}-供应商发货` : `仓库：${vendor.warehouseName}`}
                  </span>
                ) : (
                  <>
                    档口：
                    {vendor.vendorName}
                    <span className={styles.vendorAddress}>
                      地址：
                      {vendor.address}
                    </span>
                  </>
                )
              }
            </div>
            {vendor.goodsVos && vendor.goodsVos.map((goods) => {
              return (
                <div
                  className={styles.goodsItem}
                  key={goods.goodsId}
                >
                  <div className={styles.goodsInfo}>
                    <img
                      className={styles.mainPic}
                      src={goods.mainPicUrl}
                    />
                    <div className={styles.flexColumn}>
                      <div className={styles.goodsName}>
                        {goods.goodsName}
                      </div>
                      <div className={styles.tag}>
                        <Tag
                          returnRate={goods?.returnRate}
                          returnable={goods?.returnable as (1 | 2)}
                          type="return"
                        />
                        <div
                          className={styles.detailBtn}
                          onClick={() => window.open(replacePath(`/egenie-ts-vogue/goodsDetail/index?goodsId=${goods.goodsId}`))}
                        >
                          详情
                          <img
                            alt=""
                            className={styles.rightArr}
                            src={`${PUBLIC_IMAGE_URL}rightArrowRed.png`}
                          />
                        </div>
                      </div>
                    </div>

                  </div>
                  <div style={{ flex: 1 }}>
                    {goods.skuVos && goods.skuVos.map((sku) => {
                      const totalPrice = splitPrice(sku.totalPrice);
                      return (
                        <div
                          className={styles.skuInfo}
                          key={sku.mallShoppingCartId}
                        >
                          <div className={styles.colorAndSize}>
                            <span>
                              颜色：
                              {sku.color}
                            </span>
                            <span>
                              尺码：
                              {sku.size}
                            </span>
                          </div>
                          <div className={styles.price}>
                            <Price
                              activityPrice={sku.activityPrice}
                              discountPrice={sku.discountPrice}
                              isShowText={false}
                              price={sku.price}
                            />
                          </div>
                          <div className={styles.num}>
                            x
                            {sku.skuNum}
                          </div>
                          <div className={styles.totalPrice}>
                            <span className={styles.priceIcon}>
                              &yen;
                            </span>
                            <span className={styles.intPart}>
                              {totalPrice.intPart}
                              .
                            </span>
                            <span>
                              {totalPrice.decimalPart}
                            </span>
                          </div>
                        </div>
                      );
                    }
                    )}
                  </div>
                </div>
              );
            })}
            <div className={styles.footer}>
              <span style={{ marginRight: 8 }}>
                {vendor.totalNum}
                件商品
              </span>
              <span className={styles.ml4}>
                总金额：
                {`¥${totalAmount?.toFixed(2)}`}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
});

export { GoodsList };
