import { Form, Modal, Radio } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';

const FormItem = Form.Item;

export const SetCourier = observer((props) => {
  const {
    visible,
    confirmCourier,
    closeVisible,
    copyWmsCourier,
    onChangeCourier,
    setDefaultCourier,
  } = props.store;
  const allWmsMapCouriers = Object.keys(copyWmsCourier || {});
  return (
    <Modal
      centered
      className={styles.modal}
      maskClosable={false}
      onCancel={closeVisible}
      onOk={confirmCourier}
      open={visible}
      title="请选择发货快递"
      width={510}
    >
      {
        allWmsMapCouriers?.map((key) => {
          const currentWms = copyWmsCourier?.[key];
          return (
            <div
              className={styles.wmsNameWrap}
              key={key}
            >
              <div className={styles.wmsName}>
                {currentWms?.wmsName}
              </div>
              <div>
                <Radio.Group
                  onChange={(e) => {
                    onChangeCourier(e, key);
                  }}
                  value={currentWms?.currentCourierId}
                >
                  {
                    currentWms?.postList?.map((item) => {
                      return (
                        <div
                          className={styles.courierItem}
                          key={item.courierId}
                        >
                          <Radio value={item.courierId}>
                            <div className={styles.courierInfo}>
                              {item.courierName}
                              {key !== '-100' ? (
                                <span>
                                  （首件¥
                                  {item.firstFee}
                                  ，
                                  续件
                                  ¥
                                  {item.continuedFee}
                                  /件）
                                </span>
                              ) : null}
                              {currentWms?.defaultCourierId === item.courierId && (
                                <div className={styles.defaultCourier}>
                                  默认快递
                                </div>
                              )}
                              {currentWms?.defaultCourierId === item.courierId ? null : (
                                <div
                                  className={styles.setDefaultCourier}
                                  onClick={(e) => {
                                    // e.preventDefault();
                                    setDefaultCourier(key, item.courierId);
                                  }}
                                >
                                  设为默认快递
                                </div>
                              )}
                            </div>

                          </Radio>
                        </div>

                      );
                    })
                  }
                </Radio.Group>
              </div>
            </div>
          );
        })
      }
    </Modal>
  );
});
