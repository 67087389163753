import { CheckCircleFilled } from '@ant-design/icons';
import { Button, Modal, Space } from 'antd';
import { request } from 'egenie-utils';
import React, { useEffect, useState } from 'react';
import type { User } from 'egenie-common';
import { api } from '../../../../utils';
import styles from './index.less';

export const SuccessModal: React.FC<{ modalVisible: boolean; }> = (props) => {
  const { modalVisible } = props;

  const [
    visible,
    setVisible,
  ] = useState(false);

  const closeVisible = (): void => {
    setVisible(() => false);
    window.close();
  };

  useEffect(() => {
    setVisible(modalVisible);
  }, [modalVisible]);

  const jumpToOrderList = async(): Promise<void> => {
    const userInfo: User = await request({ url: api.user });
    if (userInfo.tenantType?.includes('100001')) {
      window.open(`${api.switchToErpHome}?jumpTo=myOrder`); // erp账户跳转到erp首页
    } else {
      window.open('/egenie-erp-home/shjHome?jumpTo=myOrder');
    }
    window.close();
  };

  return (
    <Modal
      centered
      className={styles.modal}
      closable={false}
      footer={null}
      maskClosable={false}
      open={visible}
      width={316}
    >
      <div className={styles.icon}>
        <CheckCircleFilled/>
      </div>
      <p className={styles.tips}>
        订单提交成功，如需查看详情，请前往商家工作台&gt;衫海精订单
      </p>
      <div className={styles.btnLine}>
        <Space align="end">
          <Button onClick={closeVisible}>
            关闭
          </Button>
          <Button
            onClick={jumpToOrderList}
            type="primary"
          >
            立即查看
          </Button>
        </Space>
      </div>
    </Modal>
  );
};
